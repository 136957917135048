// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-search-js": () => import("./../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-js": () => import("./../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-collection-js": () => import("./../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-journal-entry-js": () => import("./../../src/templates/journal_entry.js" /* webpackChunkName: "component---src-templates-journal-entry-js" */),
  "component---src-templates-journal-js": () => import("./../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-note-js": () => import("./../../src/templates/note.js" /* webpackChunkName: "component---src-templates-note-js" */),
  "component---src-templates-notes-js": () => import("./../../src/templates/notes.js" /* webpackChunkName: "component---src-templates-notes-js" */),
  "component---src-templates-review-js": () => import("./../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-reviews-js": () => import("./../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-series-js": () => import("./../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

